<template>
    <vineyard-profile-component/>
</template>

<script>
    import VineyardProfileComponent from "@/components/vineyards/VineyardProfileComponent";
    
    export default {
        name: "VineyardProfile",
        title: "Perfil del Viñedo | Turismo BC",
        components: { VineyardProfileComponent }
    }
</script>

<style scoped>

</style>